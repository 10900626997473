// Hero.tsx
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Gloria+Hallelujah&display=swap');

          .paper-texture {
            background-color: #fff;
            background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.8' numOctaves='4' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100' height='100' filter='url(%23noise)' opacity='0.08'/%3E%3C/svg%3E");
          }

          .sketchy-border {
            border: 3px solid #000;
            border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
          }

          .sketchy-button {
            background: #FCD34D;
            border: 3px solid #000;
            border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
            padding: 1.2rem 2.5rem;
            position: relative;
            transition: all 0.3s ease;
          }

          .sketchy-button:hover {
            transform: translate(-6px, -6px);
            box-shadow: 6px 6px 0px #000;
            background: #FFE580;
          }

          .doodle-underline {
            position: relative;
            display: inline-block;
          }

          .doodle-underline::after {
            content: '';
            position: absolute;
            left: -5px;
            right: -5px;
            bottom: -8px;
            height: 20px;
            background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0,10 C20,5 40,15 60,10 S100,5 120,10' fill='none' stroke='%23FF6B6B' stroke-width='4' stroke-linecap='round'/%3E%3C/svg%3E");
            background-repeat: repeat-x;
            background-size: 120px 20px;
            z-index: -1;
          }

          .floating {
            animation: float 8s ease-in-out infinite;
          }

          @keyframes float {
            0%, 100% { transform: translateY(0) rotate(0deg); }
            50% { transform: translateY(-30px) rotate(8deg); }
          }

          .squiggle {
            animation: squiggle 3s ease-in-out infinite;
          }

          @keyframes squiggle {
            0%, 100% { transform: scaleY(1) rotate(-3deg); }
            50% { transform: scaleY(0.9) rotate(3deg); }
          }
        `}
      </style>

      <section className="min-h-screen w-full bg-[#FFFDF7] relative overflow-hidden paper-texture">
        {/* Background doodles */}
        <div className="absolute inset-0 opacity-10">
          <svg width="100%" height="100%" className="absolute">
            <pattern
              id="doodlePattern"
              x="0"
              y="0"
              width="100"
              height="100"
              patternUnits="userSpaceOnUse"
            >
              <circle cx="10" cy="10" r="2" fill="#000" />
              <path
                d="M30,30 Q40,20 50,30"
                fill="none"
                stroke="#000"
                strokeWidth="1"
              />
              <path
                d="M70,70 L80,80 L70,90"
                fill="none"
                stroke="#000"
                strokeWidth="1"
              />
              <circle
                cx="90"
                cy="90"
                r="5"
                fill="none"
                stroke="#000"
                strokeWidth="1"
              />
            </pattern>
            <rect width="100%" height="100%" fill="url(#doodlePattern)" />
          </svg>
        </div>

        <div className="container mx-auto px-8 pt-32 pb-20 relative">
          <div className="desktop:flex laptop:flex tablet:block mobile:block items-center justify-between gap-24">
            {/* Left Content Section */}
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full relative z-10"
            >
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.2, type: "spring", stiffness: 200 }}
                className="absolute -top-24 -left-16 w-32 h-32 squiggle"
              >
                <svg viewBox="0 0 100 100">
                  <path
                    d="M20,50 Q50,10 80,50 Q50,90 20,50"
                    fill="#FFB23F"
                    stroke="#000"
                    strokeWidth="3"
                  />
                  <circle cx="50" cy="50" r="8" fill="#FF6B6B" />
                </svg>
              </motion.div>

              <h1
                className="desktop:text-[9rem] laptop:text-8xl tablet:text-7xl mobile:text-6xl font-bold mb-8"
                style={{ fontFamily: "Caveat, cursive" }}
              >
                <motion.span
                  className="doodle-underline inline-block"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                >
                  test
                </motion.span>
              </h1>

              <motion.p
                className="desktop:text-4xl laptop:text-3xl tablet:text-2xl mobile:text-xl mb-16 leading-relaxed text-gray-700"
                style={{ fontFamily: "Gloria Hallelujah, cursive" }}
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
              >
                test - hand-drawn sketchy style with doodles and casual
                handwriting font
              </motion.p>

              <motion.div
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.7 }}
              >
                <Link to="/start">
                  <button
                    className="sketchy-button text-3xl font-bold"
                    style={{ fontFamily: "Caveat, cursive" }}
                  >
                    Get Started
                    <motion.span
                      animate={{ x: [0, 8, 0] }}
                      transition={{ duration: 1.5, repeat: Infinity }}
                      className="inline-block ml-3"
                    >
                      →
                    </motion.span>
                  </button>
                </Link>
              </motion.div>
            </motion.div>

            {/* Right Illustration Section */}
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8 }}
              className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full desktop:mt-0 laptop:mt-0 tablet:mt-20 mobile:mt-16 relative"
            >
              <div className="relative">
                <div className="sketchy-border bg-white p-10 rotate-3 shadow-[12px_12px_0px_0px_rgba(0,0,0,1)]">
                  <div className="bg-yellow-50 p-8 -rotate-2 sketchy-border">
                    <svg className="w-full h-auto" viewBox="0 0 400 300">
                      <defs>
                        <pattern
                          id="grid"
                          width="40"
                          height="40"
                          patternUnits="userSpaceOnUse"
                        >
                          <path
                            d="M 40 0 L 0 0 0 40"
                            fill="none"
                            stroke="#ddd"
                            strokeWidth="1"
                          />
                        </pattern>
                      </defs>
                      <rect width="100%" height="100%" fill="url(#grid)" />

                      {/* Main illustration */}
                      <g className="floating">
                        <circle
                          cx="200"
                          cy="150"
                          r="80"
                          fill="#FFB23F"
                          stroke="#000"
                          strokeWidth="4"
                        />
                        <path
                          d="M160,140 C180,120 220,120 240,140"
                          fill="none"
                          stroke="#000"
                          strokeWidth="4"
                          strokeLinecap="round"
                        />
                        <circle cx="175" cy="120" r="15" fill="#000" />
                        <circle cx="225" cy="120" r="15" fill="#000" />
                        <path
                          d="M175,180 Q200,200 225,180"
                          fill="none"
                          stroke="#000"
                          strokeWidth="5"
                          strokeLinecap="round"
                        />
                      </g>

                      {/* Decorative elements */}
                      <g className="squiggle">
                        <path
                          d="M50,50 Q80,20 110,50"
                          fill="none"
                          stroke="#FF6B6B"
                          strokeWidth="4"
                          strokeLinecap="round"
                        />
                        <path
                          d="M290,250 Q320,220 350,250"
                          fill="none"
                          stroke="#FF6B6B"
                          strokeWidth="4"
                          strokeLinecap="round"
                        />
                        <circle
                          cx="320"
                          cy="70"
                          r="20"
                          fill="none"
                          stroke="#000"
                          strokeWidth="3"
                          strokeDasharray="8,8"
                        />
                        <path
                          d="M60,220 L90,250 L60,280"
                          fill="none"
                          stroke="#000"
                          strokeWidth="3"
                          strokeLinecap="round"
                        />
                      </g>
                    </svg>
                  </div>
                </div>

                {/* Floating decorative elements */}
                <motion.div
                  animate={{
                    y: [-15, 15, -15],
                    rotate: [0, 15, 0],
                  }}
                  transition={{ duration: 5, repeat: Infinity }}
                  className="absolute -top-16 -right-16 w-32 h-32"
                >
                  <svg viewBox="0 0 100 100">
                    <path
                      d="M20,50 Q50,20 80,50 Q50,80 20,50"
                      fill="#FF6B6B"
                      stroke="#000"
                      strokeWidth="3"
                    />
                    <circle
                      cx="50"
                      cy="50"
                      r="10"
                      fill="#FFB23F"
                      stroke="#000"
                      strokeWidth="2"
                    />
                  </svg>
                </motion.div>

                <motion.div
                  animate={{
                    y: [15, -15, 15],
                    rotate: [0, -15, 0],
                  }}
                  transition={{ duration: 4, repeat: Infinity }}
                  className="absolute -bottom-16 -left-16 w-28 h-28"
                >
                  <svg viewBox="0 0 100 100">
                    <circle
                      cx="50"
                      cy="50"
                      r="30"
                      fill="#FFB23F"
                      stroke="#000"
                      strokeWidth="3"
                    />
                    <path
                      d="M35,50 L65,50 M50,35 L50,65"
                      stroke="#000"
                      strokeWidth="3"
                      strokeLinecap="round"
                    />
                  </svg>
                </motion.div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
